import * as React from "react";
import * as moment from "moment";
import { ColumnFiltersState, createColumnHelper } from "components/tableV2";
import { MachineOverviewsTableDataRow } from "./mapDataToTable";
import Icon from "common/components/icon/Icon";
import { LastSoldEnum } from "../type";
import {
  MachineAlarmTypeEnum,
  MachineInventoryStatusEnum,
  MachineStatusEnum,
} from "gql/generated";
import { clsx } from "clsx";
import { TFunction } from "i18next";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { formatCurrency, formatNumber } from "utils/currency";
import { getErrorMessage } from "./getErrorCode";
import { LocationCellModal } from "./locationCellModal";

const columnHelper = createColumnHelper<MachineOverviewsTableDataRow>();

const BASE_KEY_PREFIX = "overview_page.table";

export function generateColumnConfig(
  filter: ColumnFiltersState,
  translation: TFunction<"machine", undefined>,
  language: string
) {
  const moduleOptions = generateModuleOptions(translation);
  function t(key: string) {
    return translation(`${BASE_KEY_PREFIX}.${key}`);
  }
  return [
    columnHelper.accessor("machineId", {
      header: () => <>{t("columns.machineId_label")}</>,
      cell: (info) => (
        <Link
          className="!font-medium text-primary hover:underline"
          to={`/machines/detail/${info.getValue()}/inventory`}
        >
          {info.getValue()}
        </Link>
      ),
      enableColumnFilter: true,
      minSize: 70,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          label: t("filters.machineId_label"),
        },
      },
    }),
    columnHelper.accessor("status", {
      header: () => (
        <div className="w-full text-center">{t("columns.status_label")}</div>
      ),
      cell: (info) => {
        switch (info.getValue()) {
          case MachineStatusEnum.Online:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.Online}
                label={t("filters.machine_status.options.online_label")}
              />
            );
          case MachineStatusEnum.Offline:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.Offline}
                label={t("filters.machine_status.options.offline_label")}
              />
            );
          case MachineStatusEnum.Maintenance:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.Maintenance}
                label={t("filters.machine_status.options.maintenance_label")}
              />
            );
          case MachineStatusEnum.OutOfService:
            return (
              <MachineStatusLabel
                className="my-0.5"
                status={MachineStatusEnum.OutOfService}
                label={t("filters.machine_status.options.out_of_service_label")}
              />
            );
          default:
            return null;
        }
      },
      enableColumnFilter: true,
      minSize: 115,
      meta: {
        columnFilter: {
          type: "select",
          location: "secondary",
          label: t("filters.machine_status.label"),
          options: [
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Online}
                  label={t("filters.machine_status.options.online_label")}
                />
              ),
              value: MachineStatusEnum.Online,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Offline}
                  label={t("filters.machine_status.options.offline_label")}
                />
              ),
              value: MachineStatusEnum.Offline,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.OutOfService}
                  label={t(
                    "filters.machine_status.options.out_of_service_label"
                  )}
                />
              ),
              value: MachineStatusEnum.OutOfService,
            },
            {
              label: (
                <MachineStatusLabel
                  className="my-0.5"
                  status={MachineStatusEnum.Maintenance}
                  label={t("filters.machine_status.options.maintenance_label")}
                />
              ),
              value: MachineStatusEnum.Maintenance,
            },
          ],
        },
      },
    }),
    columnHelper.accessor("locationName", {
      header: () => <>{t("columns.location_name_label")}</>,
      cell: (info) => (
        <div className="flex items-center gap-1">
          <p className="flex-1">{info.getValue()}</p>
          <LocationCellModal
            locationName={info.row.original.locationName}
            locationId={info.row.original.locationId}
            coordinates={info.row.original.coordinates}
            machineId={info.row.original.machineId}
          />
        </div>
      ),
      enableColumnFilter: true,
      minSize: 150,
      meta: {
        columnFilter: {
          type: "text",
          location: "primary",
          label: t("filters.location_name_label"),
        },
      },
    }),
    columnHelper.accessor("organizationName", {
      header: () => <>{t("columns.organization_name_label")}</>,
      cell: (info) => <>{info.getValue()}</>,
      minSize: 80,
    }),
    columnHelper.accessor("serviceZoneName", {
      header: () => <>{t("columns.service_zone_name_label")}</>,
      cell: (info) => <>{info.getValue() || " - "}</>,
      enableColumnFilter: true,
      minSize: 60,
      meta: {
        columnFilter: {
          type: "text",
          location: "modal",
          label: t("filters.service_zone_name_label"),
        },
      },
    }),
    columnHelper.accessor("refillZoneName", {
      header: () => <>{t("columns.refill_zone_name_label")}</>,
      cell: (info) => <>{info.getValue() || " - "}</>,
      enableColumnFilter: true,
      minSize: 60,
      meta: {
        columnFilter: {
          type: "text",
          location: "modal",
          label: t("filters.refill_zone_name_label"),
        },
      },
      enableSorting: true,
    }),
    columnHelper.accessor("todayCupSold", {
      header: () => (
        <div>
          <p className="whitespace-nowrap">
            {t("columns.today_cups_sold_label")}
          </p>
          <p className="text-on-surface-medium">{t("columns.cups_label")}</p>
        </div>
      ),
      cell: (info) => <>{formatNumber(info.getValue())}</>,
      enableColumnFilter: true,
      enableSorting: true,
      minSize: 60,
    }),
    columnHelper.accessor("todaySale", {
      header: () => (
        <div className="whitespace-nowrap">
          {t("columns.today_sales_label")}
        </div>
      ),
      cell: (info) => (
        <div className="text-right pr-3 whitespace-nowrap">
          {formatCurrency({ input: info.getValue() })}
        </div>
      ),
      enableColumnFilter: true,
      enableSorting: true,
      meta: {
        columnFilter: {
          type: "minmax",
          location: "modal",
          label: t("filters.today_sales_label"),
        },
      },
    }),
    columnHelper.accessor("expectedSale", {
      header: () => (
        <div className="whitespace-nowrap">
          {t("columns.expected_sales_label")}
        </div>
      ),
      cell: (info) => (
        <div className="text-right pr-3 whitespace-nowrap">
          {formatCurrency({ input: info.getValue() })}
        </div>
      ),
      enableColumnFilter: true,
      enableSorting: true,
      meta: {
        columnFilter: {
          type: "minmax",
          location: "modal",
          label: t("filters.expected_sales_label"),
        },
      },
    }),
    columnHelper.accessor("lastSold", {
      header: () => (
        <div className="whitespace-nowrap">{t("columns.last_sold_label")}</div>
      ),
      cell: (info) => <LastSellCell date={info.getValue()} />,
      enableColumnFilter: true,
      enableSorting: true,
      minSize: 100,
      meta: {
        columnFilter: {
          type: "select",
          label: t("filters.last_sold.label"),
          options: [
            {
              label: (
                <div className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-success" />
                  <p className="!font-kanit">
                    {t("filters.last_sold.options.less_than_30_mins_label")}
                  </p>
                </div>
              ),
              value: LastSoldEnum.LessThan30Mins,
            },
            {
              label: (
                <div className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-success" />
                  <p className="!font-kanit">
                    {t("filters.last_sold.options.thirty_mins_to_one_hr_label")}
                  </p>
                </div>
              ),
              value: LastSoldEnum.ThirtyMinsOneHr,
            },
            {
              label: (
                <div className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-success" />
                  <p className="!font-kanit">
                    {t("filters.last_sold.options.one_hr_to_two_hrs_label")}
                  </p>
                </div>
              ),
              value: LastSoldEnum.OneHrTwoHrs,
            },
            {
              label: (
                <div className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-warning" />
                  <p className="!font-kanit">
                    {t("filters.last_sold.options.more_than_2_hrs_label")}
                  </p>
                </div>
              ),
              value: LastSoldEnum.MoreThan2Hrs,
            },
            {
              label: (
                <div className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-warning" />
                  <p className="!font-kanit">
                    {t("filters.last_sold.options.more_than_1_day_label")}
                  </p>
                </div>
              ),
              value: LastSoldEnum.MoreThan1Day,
            },
            {
              label: (
                <div className="flex gap-2 items-center">
                  <div className="w-2 h-2 rounded-full bg-error" />
                  <p className="!font-kanit">
                    {t("filters.last_sold.options.more_than_7_days_label")}
                  </p>
                </div>
              ),
              value: LastSoldEnum.MoreThan7Days,
            },
          ],
          location: "secondary",
        },
      },
    }),
    columnHelper.accessor("sevenDaysAgoSale", {
      header: () => <>{t("columns.seven_day_ago_sales_label")}</>,
      cell: (info) => (
        <>
          {formatCurrency({ input: info.getValue(), minimumFractionDigits: 0 })}
        </>
      ),
      minSize: 60,
    }),
    columnHelper.accessor("revenueSinceLastRefill", {
      header: () => <>{t("columns.revenue_since_last_refill_label")}</>,
      cell: (info) => (
        <>
          {formatCurrency({ input: info.getValue(), minimumFractionDigits: 0 })}
        </>
      ),
      minSize: 90,
    }),
    columnHelper.accessor("lastRefillDate", {
      header: () => <>{t("columns.last_refill_label")}</>,
      cell: (info) => (
        <div className="whitespace-nowrap">
          {moment(info.getValue()).format("MMM DD, YYYY")}
        </div>
      ),
      minSize: 60,
    }),
    columnHelper.accessor("disabledSlot", {
      header: () => <>{t("columns.disable_slot_label")}</>,
      cell: (info) => <>{info.getValue()}</>,
      minSize: 60,
    }),
    columnHelper.accessor("ice", {
      header: () => (
        <p className="text-center w-full">{t("columns.ice_label")}</p>
      ),
      size: 42,
      minSize: 42,
      cell: (info) => (
        <Tooltip
          placement="top"
          title={`${t("columns.ice_label")}: ${renderMachineInventoryStatusOptionLabel(
            {
              status: info.getValue(),
              t: translation,
            }
          )}`}
        >
          <div className="flex items-center justify-center">
            <StatusIcon status={info.getValue()} />
          </div>
        </Tooltip>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.accessor("soda", {
      header: () => (
        <p className="text-center w-full">{t("columns.soda_label")}</p>
      ),
      size: 32,
      minSize: 32,
      cell: (info) => (
        <Tooltip
          placement="top"
          title={`${t("columns.soda_label")}: ${renderMachineInventoryStatusOptionLabel(
            {
              status: info.getValue(),
              t: translation,
            }
          )}`}
        >
          <div className="flex items-center justify-center">
            <StatusIcon status={info.getValue()} />
          </div>
        </Tooltip>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.accessor("coffee", {
      header: () => (
        <div>
          <p className="text-center font-kanit text-on-surface-high">
            {t("columns.coffee_label")}
          </p>
          <div className="text-center flex text-on-surface-medium">
            <p className="h-5 w-7">{t("columns.coffee_b1_label")}</p>
            <p className="h-5 w-7">{t("columns.coffee_b2_label")}</p>
            <p className="h-5 w-7">{t("columns.coffee_b3_label")}</p>
          </div>
        </div>
      ),
      size: 84,
      cell: (info) => (
        <div className="flex">
          <Tooltip
            placement="top"
            title={`${t("columns.coffee_b1_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().B1,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-7 flex items-center justify-center">
              <StatusIcon status={info.getValue().B1} />
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`${t("columns.coffee_b2_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().B2,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-7 flex items-center justify-center">
              <StatusIcon status={info.getValue().B2} />
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`${t("columns.coffee_b3_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().B3,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-7 flex items-center justify-center">
              <StatusIcon status={info.getValue().B3} />
            </div>
          </Tooltip>
        </div>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.accessor("milk", {
      header: () => (
        <p className="text-center w-full">{t("columns.milk_label")}</p>
      ),
      size: 32,
      cell: (info) => (
        <Tooltip
          placement="top"
          title={`${t("columns.milk_label")}: ${renderMachineInventoryStatusOptionLabel(
            {
              status: info.getValue(),
              t: translation,
            }
          )}`}
        >
          <div className="flex items-center justify-center">
            <StatusIcon status={info.getValue()} />
          </div>
        </Tooltip>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.accessor("sugar", {
      header: () => (
        <p className="text-center w-full whitespace-nowrap">
          {t("columns.sugar_label")}
        </p>
      ),
      size: 32,
      minSize: 32,
      cell: (info) => (
        <Tooltip
          placement="top"
          title={`${t("columns.sugar_label")}: ${renderMachineInventoryStatusOptionLabel(
            {
              status: info.getValue(),
              t: translation,
            }
          )}`}
        >
          <div className="flex items-center justify-center">
            <StatusIcon status={info.getValue()} />
          </div>
        </Tooltip>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.accessor("cups", {
      header: () => (
        <div>
          <p className="text-center font-kanit text-on-surface-high">
            {t("columns.cups_label")}
          </p>
          <div className="text-center flex text-on-surface-medium">
            <p className="h-5 w-7">{t("columns.cups_c1_label")}</p>
            <p className="h-5 w-7">{t("columns.cups_c2_label")}</p>
            <p className="h-5 w-7">{t("columns.cups_c3_label")}</p>
          </div>
        </div>
      ),
      size: 84,
      cell: (info) => (
        <div className="flex">
          <Tooltip
            placement="top"
            title={`${t("columns.cups_c1_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().C1,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-7 flex items-center justify-center">
              <StatusIcon status={info.getValue().C1} />
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`${t("columns.cups_c2_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().C2,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-7 flex items-center justify-center">
              <StatusIcon status={info.getValue().C2} />
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`${t("columns.cups_c3_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().C3,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-7 flex items-center justify-center">
              <StatusIcon status={info.getValue().C3} />
            </div>
          </Tooltip>
        </div>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.accessor("lid", {
      header: () => (
        <p className="text-center w-full">{t("columns.lids_label")}</p>
      ),
      size: 36,
      cell: (info) => (
        <div className="flex items-center justify-center">
          <Tooltip
            placement="top"
            title={`${t("columns.lids_l1_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().L1,
                t: translation,
              }
            )}`}
          >
            <div>
              <StatusIcon status={info.getValue().L1} />
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`${t("columns.lids_l2_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().L2,
                t: translation,
              }
            )}`}
          >
            <div>
              <StatusIcon status={info.getValue().L2} />
            </div>
          </Tooltip>
        </div>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.accessor("cash", {
      header: () => (
        <div>
          <p className="text-center font-kanit text-on-surface-high">
            {t("columns.cash_label")}
          </p>
          <div className="text-center flex text-on-surface-medium">
            <p className="h-5 w-10">{t("columns.cash_bill_label")}</p>
            <p className="h-5 w-10">{t("columns.cash_coin_label")}</p>
          </div>
        </div>
      ),
      size: 56,
      cell: (info) => (
        <div className="flex">
          <Tooltip
            placement="top"
            title={`${t("columns.cash_bill_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().BANK,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-10 flex items-center justify-center">
              <StatusIcon status={info.getValue().BANK} />
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            title={`${t("columns.cash_coin_label")}: ${renderMachineInventoryStatusOptionLabel(
              {
                status: info.getValue().COIN,
                t: translation,
              }
            )}`}
          >
            <div className="h-5 w-10 flex items-center justify-center">
              <StatusIcon status={info.getValue().COIN} />
            </div>
          </Tooltip>
        </div>
      ),
      meta: {
        columnBorder: true,
      },
    }),
    columnHelper.display({
      id: "module",
      header: () => <div />,
      cell: () => <div />,
      enableColumnFilter: true,
      minSize: 100,
      meta: {
        hide: true,
        columnFilter: {
          type: "checkbox",
          location: "modal",
          label: t("filters.module.label"),
          helperText: t("filters.module.label_helper_text"),
          order: 2,
          options: moduleOptions,
          exclude: [...new Set(moduleOptions.map((option) => option.parent))],
          disabled:
            filter.findIndex((item) => item.id === "machineInventoryStatus") ===
            -1,
        },
      },
    }),
    columnHelper.display({
      id: "machineInventoryStatus",
      header: () => <div />,
      cell: () => <div />,
      enableColumnFilter: true,
      meta: {
        hide: true,
        columnFilter: {
          type: "selectBadge",
          location: "modal",
          label: t("filters.machine_inventory_status.label"),
          order: 1,
          options: [
            {
              label: (
                <MachineInventoryStatusOption
                  status={MachineInventoryStatusEnum.Disable}
                  t={translation}
                />
              ),
              value: MachineInventoryStatusEnum.Disable,
            },
            {
              label: (
                <MachineInventoryStatusOption
                  status={MachineInventoryStatusEnum.Abnormal}
                  t={translation}
                />
              ),
              value: MachineInventoryStatusEnum.Abnormal,
            },
            {
              label: (
                <MachineInventoryStatusOption
                  status={MachineInventoryStatusEnum.Off}
                  t={translation}
                />
              ),
              value: MachineInventoryStatusEnum.Off,
            },
            {
              label: (
                <MachineInventoryStatusOption
                  status={MachineInventoryStatusEnum.DisableLow}
                  t={translation}
                />
              ),
              value: MachineInventoryStatusEnum.DisableLow,
            },
            {
              label: (
                <MachineInventoryStatusOption
                  status={MachineInventoryStatusEnum.Low}
                  t={translation}
                />
              ),
              value: MachineInventoryStatusEnum.Low,
            },
            {
              label: (
                <MachineInventoryStatusOption
                  status={MachineInventoryStatusEnum.Normal}
                  t={translation}
                />
              ),
              value: MachineInventoryStatusEnum.Normal,
            },
          ],
        },
      },
    }),
    columnHelper.accessor("error", {
      header: () => (
        <div className="whitespace-nowrap">{t("columns.error_label")}</div>
      ),
      cell: (info) => {
        const errorMessage = getErrorMessage({
          code: info.getValue(),
          language,
        });
        if (errorMessage)
          return (
            <Tooltip
              placement="top"
              title={getErrorMessage({ code: info.getValue(), language })}
            >
              <div className="text-error font-medium underline cursor-pointer">
                {info.getValue()}
              </div>
            </Tooltip>
          );
        return <div className="text-error">{info.getValue()}</div>;
      },
      minSize: 40,
      enableColumnFilter: true,
      meta: {
        bodyClassName: "",
        columnFilter: {
          type: "checkbox",
          location: "modal",
          label: t("filters.error.label"),
          options: [
            {
              label: t("filters.error.options.error_label"),
              value: MachineAlarmTypeEnum.Error,
            },
            {
              label: t("filters.error.options.warning_label"),
              value: MachineAlarmTypeEnum.Warning,
            },
            {
              label: t("filters.error.options.normal_label"),
              value: MachineAlarmTypeEnum.Normal,
            },
          ],
        },
      },
    }),
  ];
}

const MachineStatusLabel = ({
  status,
  label,
  className,
}: {
  status: MachineStatusEnum;
  className?: string;
  label: string;
}) => {
  const baseClassName = clsx(
    "h-4 leading-4 text-caption px-4 py-1 rounded-full flex-1 whitespace-nowrap text-center font-kanit",
    {
      "bg-success-overlay": status === MachineStatusEnum.Online,
      "bg-on-surface-disabled text-white": status === MachineStatusEnum.Offline,
      "bg-error-200": status === MachineStatusEnum.OutOfService,
      "bg-warning-overlay": status === MachineStatusEnum.Maintenance,
    },
    className
  );

  return <div className={baseClassName}>{label}</div>;
};

function LastSellCell({ date }: { date: string }) {
  // Last Sell color logic:
  // - Green: if last sell occurred within the past 2 hours
  // - Orange: if last sell occurred 2 hours or more ago, but less than 7 days ago
  // - Red: if last sell occurred 7 days or more ago
  let color = "";
  const diffInHours = moment().diff(moment(date), "hours");

  if (diffInHours >= 0 && diffInHours < 2) {
    color = "bg-success";
  } else if (diffInHours >= 2 && diffInHours < 168) {
    color = "bg-warning";
  } else {
    color = "bg-error";
  }

  return (
    <div className="inline-block w-full items-baseline gap-1">
      {date && (
        <span className={clsx("w-2 h-2 rounded-full inline-block", color)} />
      )}{" "}
      {date ? moment(date).fromNow() : "-"}
    </div>
  );
}

function StatusIcon({ status }: { status: MachineInventoryStatusEnum }) {
  if (status === MachineInventoryStatusEnum.Normal)
    return <Icon className="w-6 h-6" name="CircleCheck" color="success" />;
  if (status === MachineInventoryStatusEnum.Abnormal)
    return <Icon className="w-6 h-6" name="AlertTriangle" color="warning" />;
  if (status === MachineInventoryStatusEnum.Disable)
    return <Icon className="w-6 h-6" name="AlertTriangle" color="error" />;
  if (status === MachineInventoryStatusEnum.Low)
    return <Icon className="w-6 h-6" name="Inventory" color="warning" />;
  if (status === MachineInventoryStatusEnum.DisableLow)
    return <Icon className="w-6 h-6" name="Inventory" color="error" />;
  return (
    <div className="w-6 h-6 flex items-center justify-center">
      <div className="h-0.5 w-4 bg-on-surface-disabled rounded-full" />
    </div>
  );
}

function renderMachineInventoryStatusOptionLabel({
  status,
  t,
}: {
  status: MachineInventoryStatusEnum;
  t: TFunction<"machine", undefined>;
}) {
  switch (status) {
    case MachineInventoryStatusEnum.Normal:
      return t(
        "overview_page.table.filters.machine_inventory_status.options.label_normal"
      );
    case MachineInventoryStatusEnum.Abnormal:
      return t(
        "overview_page.table.filters.machine_inventory_status.options.label_abnormal"
      );
    case MachineInventoryStatusEnum.Disable:
      return t(
        "overview_page.table.filters.machine_inventory_status.options.label_disable"
      );
    case MachineInventoryStatusEnum.DisableLow:
      return t(
        "overview_page.table.filters.machine_inventory_status.options.label_disable_low"
      );
    case MachineInventoryStatusEnum.Off:
      return t(
        "overview_page.table.filters.machine_inventory_status.options.label_off"
      );
    case MachineInventoryStatusEnum.Low:
      return t(
        "overview_page.table.filters.machine_inventory_status.options.label_low"
      );
  }
}

function MachineInventoryStatusOption({
  status,
  t,
}: {
  status: MachineInventoryStatusEnum;
  t: TFunction<"machine", undefined>;
}) {
  return (
    <div className="flex gap-2 items-center font-kanit">
      <StatusIcon status={status} />
      <p className="text-body2 font-medium text-on-surface-medium">
        {renderMachineInventoryStatusOptionLabel({ status, t })}
      </p>
    </div>
  );
}

function generateModuleOptions(t: TFunction<"machine", undefined>) {
  return [
    {
      label: t("overview_page.table.filters.module.options.ice_label"),
      value: "ice",
    },
    {
      label: t("overview_page.table.filters.module.options.soda_label"),
      value: "soda",
    },
    {
      label: t("overview_page.table.filters.module.options.coffee_label"),
      value: "coffee",
    },
    {
      label: t("overview_page.table.filters.module.options.coffee_b1_label"),
      value: "B1",
      parent: "coffee",
    },
    {
      label: t("overview_page.table.filters.module.options.coffee_b2_label"),
      value: "B2",
      parent: "coffee",
    },
    {
      label: t("overview_page.table.filters.module.options.coffee_b3_label"),
      value: "B3",
      parent: "coffee",
    },
    {
      label: t("overview_page.table.filters.module.options.milk_label"),
      value: "milk",
    },
    {
      label: t("overview_page.table.filters.module.options.sugar_label"),
      value: "sugar",
    },
    {
      label: t("overview_page.table.filters.module.options.cups_label"),
      value: "cups",
    },
    {
      label: t("overview_page.table.filters.module.options.cups_c1_label"),
      value: "C1",
      parent: "cups",
    },
    {
      label: t("overview_page.table.filters.module.options.cups_c2_label"),
      value: "C2",
      parent: "cups",
    },
    {
      label: t("overview_page.table.filters.module.options.cups_c3_label"),
      value: "C3",
      parent: "cups",
    },
    {
      label: t("overview_page.table.filters.module.options.lids_label"),
      value: "lids",
    },
    {
      label: t("overview_page.table.filters.module.options.lids_l1_label"),
      value: "L1",
      parent: "lids",
    },
    {
      label: t("overview_page.table.filters.module.options.lids_l2_label"),
      value: "L2",
      parent: "lids",
    },
    {
      label: t("overview_page.table.filters.module.options.cash_label"),
      value: "cash",
    },
    {
      label: t("overview_page.table.filters.module.options.cash_bill_label"),
      value: "BANK",
      parent: "cash",
    },
    {
      label: t("overview_page.table.filters.module.options.cash_coin_label"),
      value: "COIN",
      parent: "cash",
    },
  ];
}
