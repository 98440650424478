import * as React from "react";
import { Redirect } from "react-router";
import { useAuthStore } from "userandauth/useAuthStore";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/browser";

import Icon from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import { CircularProgress } from "@mui/material";
import { httpClient } from "common/services/transportService";

type StatusType = "processing" | "success" | "fail";

export function ValidateResetPasswordToken(): JSX.Element {
  const { search } = useLocation();
const searchParams = new URLSearchParams(search);
const token = searchParams.get("token");
  const [status, setStatus] = React.useState<StatusType>("processing");
  const { t } = useTranslation("authentication", {
    keyPrefix: "validate_reset_password_token",
  });

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  async function verifyToken(token: string) {
    try {
      const response = await httpClient.get(`/auth/validate-password-reset?token=${token}`);
      if(response.status === 200) {
        setStatus("success");
      } else {
        setStatus("fail");
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  React.useEffect(() => {
    if(!token) return;
    verifyToken(token);
  }, [token]);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }
  

  return (
    <div className="flex flex-col items-center h-screen bg-navigation">
      <div className="z-10 mt-16 mx-5 flex flex-col items-center w-full box-border sm:mt-5">
        <div className="w-36 mb-8 sm:80 sm:mb-5">
          <Icon name="Vendii" className="w-full h-auto block" />
        </div>
        <div className="flex flex-col max-w-md w-full bg-surface rounded-lg p-10 mx-5 box-border shadow-lg sm:p-5">
          <Typography
            type="headline-6"
            translationKey="Reset Password"
            className="mb-5 text-center"
          />
          {status === "processing" && (
            <div className="font-kanit text-on-surface-high p-4 rounded-md border border-warning bg-warning-overlay">
              <div className="flex justify-center">
                <CircularProgress />
              </div>
              <div className="text-h6 font-medium mb-3">
                {t("verify_request_title")}
              </div>
              <p>{t("verify_request_message")}</p>
            </div>
          )}
          {status === "success" && (
            <div className="font-kanit text-on-surface-high p-4 rounded-md border border-success bg-success-overlay">
              <div className="text-h6 font-medium mb-3">
                {t("validate_success_title")}
              </div>
              <p>{t("validate_success_message")}</p>
            </div>
          )}
          {status === "fail" && (
            <div className="font-kanit text-on-surface-high p-4 rounded-md border border-error bg-error-200">
              <div className="text-h6 font-medium mb-3">
                {t("verify_fail_title")}
              </div>
              <p>{t("verify_fail_message")}</p>
            </div>
          )}
          <hr className="h-px my-3 bg-outline border-0 w-full" />
          <div className="flex items-center gap-1 justify-center">
            <Icon className="-rotate-90" name="ArrowTop" color="primary" />
            <Link
              to="/login"
              className="text-primary font-main font-medium hover:underline"
            >
              {t("label_back_to_login")}
            </Link>
          </div>
        </div>
      </div>
      <img
        alt="vending city splash screen"
        className="fixed object-contain bottom-0 w-full tablet:w-auto tablet:left-0 tablet:h-[90%] mobile:h-[50%]"
        src="/images/vendingcity.png"
      />
    </div>
  );
}
