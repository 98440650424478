// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CheckboxCell--yXsdqzU1rC{display:flex;flex-direction:row;align-items:center}.CheckboxCell--yXsdqzU1rC .label--1J-z-KLAf4{margin-left:10px}.CurrencyCell--a-2xBqE_RF{white-space:nowrap}.SelectButtonCell--1hsZXXVGiR{min-width:110px}@media screen and (max-width: 768px){.SelectButtonCell--1hsZXXVGiR{min-width:0px}}.SelectButtonCellDropdown--GPTA2vDGmH{width:230px}.LinkCell--25sWPZ1F5t{color:#4b4d9b}.IconCell--oz0pIa0U2X{cursor:pointer}.InputCell--1PAuU82wIx input{width:100%;padding:8px 0 8px 8px !important;background-color:#f5f5f5 !important}.ClickableStringCell--1rFBY8Od_F{cursor:pointer;color:#4b4d9b}.FromNowCell--3uggmzn5DU{display:flex;flex-direction:column}.FromNowCell--3uggmzn5DU .postDateMessage--3cUSAKR6Qu{color:#e95d50}.FromNowCell--3uggmzn5DU .fromNow--3dNlADF7ld{color:rgba(0,0,0,.38)}.UuidCell--3iB7MZ0sPT{cursor:pointer;color:#4b4d9b;width:100%;display:flex;flex-direction:row;align-items:center}.UuidCell--3iB7MZ0sPT .copyIcon--WLjRPJHsrc{width:16px;margin-left:10px}.UuidCell--3iB7MZ0sPT .tooltip--2n_ectJ_dU{padding:10px;cursor:auto}.LastRefillCell--34Vq0-jbt2{display:flex;flex-direction:column}.LastRefillCell--34Vq0-jbt2 .expired--38zBeY6IZC{color:#e95d50}.LastRefillCell--34Vq0-jbt2 .bottomRow--2l7Wr1McBW{display:flex;flex-direction:row;align-items:center;color:rgba(0,0,0,.38)}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"CheckboxCell": "CheckboxCell--yXsdqzU1rC",
	"label": "label--1J-z-KLAf4",
	"CurrencyCell": "CurrencyCell--a-2xBqE_RF",
	"SelectButtonCell": "SelectButtonCell--1hsZXXVGiR",
	"SelectButtonCellDropdown": "SelectButtonCellDropdown--GPTA2vDGmH",
	"LinkCell": "LinkCell--25sWPZ1F5t",
	"IconCell": "IconCell--oz0pIa0U2X",
	"InputCell": "InputCell--1PAuU82wIx",
	"ClickableStringCell": "ClickableStringCell--1rFBY8Od_F",
	"FromNowCell": "FromNowCell--3uggmzn5DU",
	"postDateMessage": "postDateMessage--3cUSAKR6Qu",
	"fromNow": "fromNow--3dNlADF7ld",
	"UuidCell": "UuidCell--3iB7MZ0sPT",
	"copyIcon": "copyIcon--WLjRPJHsrc",
	"tooltip": "tooltip--2n_ectJ_dU",
	"LastRefillCell": "LastRefillCell--34Vq0-jbt2",
	"expired": "expired--38zBeY6IZC",
	"bottomRow": "bottomRow--2l7Wr1McBW"
};
module.exports = exports;
