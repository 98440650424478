import * as React from "react";
import Typography from "components/Typography";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import { Skeleton } from "@mui/material";
import { useErrorBoard } from "./hooks/useErrorBoard";
import Icon from "common/components/icon/Icon";

import * as styles from "./ErrorOverviews.module.scss";

import { OverallScoreStatus } from "./types";
import { FrequentErrorModal } from "./FrequentErrorModal";
import { Link } from "react-router-dom";
import { getLinkToOpenErrorFrequencyModal } from "./hooks/useErrorFrequencyModalSearchParams";

export function ErrorOverviews() {
  const { t } = useTranslation("errorBoard");
  const [isOpen, setIsOpen] = useLocalStorage("errorBoardErrorOverviews", true);
  const { state } = useErrorBoard();
  const errorOverviews = state.data.errorOverviews;

  const isLoading =
    !errorOverviews &&
    (state.status === "loading" || state.status === "refreshing");

  return (
    <div className={styles.ErrorOverviews}>
      <div className={styles.HeadingAndButtonContainer}>
        <Typography type="subtitle-2" color="onPrimaryHigh">
          {t("errorOverviews")}
        </Typography>

        <button
          onClick={() => setIsOpen(!isOpen)}
          className={styles.ToggleButton}
        >
          <Icon
            name="DropDownChevron"
            style={{
              transition: "transform 0.2s ease-in-out",
              transform: isOpen ? "rotate(180deg)" : null,
              transformOrigin: "center",
            }}
          />
        </button>
      </div>
      <div
        style={{
          height: isOpen ? 108 : 0,
          boxSizing: "border-box",
          transition: "height 0.2s ease-in-out",
          overflow: "hidden",
        }}
      >
        <div className={styles.ErrorOverviewContainer}>
          <div className={styles.ErrorOverviewCardsAndFrequentErrorContainer}>
            <div className={styles.ErrorOverviewsCardScroller}>
              <Card
                title={t("cards.moduleScore")}
                value={errorOverviews?.moduleScoreAmount ?? 0}
                isLoading={isLoading}
              />
              <Card
                title={t("cards.inventoryScore")}
                value={errorOverviews?.inventoryScore ?? 0}
                isLoading={isLoading}
              />

              <Card
                title={t("cards.inventoryFail")}
                value={errorOverviews?.inventoryFail.amount ?? 0}
                subLabel={`(${(errorOverviews?.inventoryFail.percentage ?? 0).toFixed(2)}%)`}
                isLoading={isLoading}
              />
              <Card
                title={t("cards.softwareHardwareFail")}
                value={errorOverviews?.softwareHardwareFail.amount ?? 0}
                subLabel={`(${(errorOverviews?.softwareHardwareFail.percentage ?? 0).toFixed(2)}%)`}
                isLoading={isLoading}
              />

              <Card
                title={t("cards.drinkFail")}
                value={errorOverviews?.drinkFail.amount ?? 0}
                subLabel={`(${(errorOverviews?.drinkFail.percentage ?? 0).toFixed(2)}%)`}
                isLoading={isLoading}
              />
              <Card
                title={t("cards.overallScore")}
                value={Number(errorOverviews?.overallScore.amount ?? 0).toFixed(
                  2
                )}
                subLabel={
                  <StatusIndicator
                    status={errorOverviews?.overallScore.status}
                  />
                }
                isLoading={isLoading}
              />
              <div
                className={
                  styles.ErrorOverviewCardScrollerFrequentErrorCardContainer
                }
              >
                <FrequentErrorCard />
              </div>
            </div>
            <div className={styles.FrequentErrorCardContainer}>
              <FrequentErrorCard />
            </div>

            <FrequentErrorModal />
          </div>
        </div>
      </div>
    </div>
  );
}

function FrequentErrorCard() {
  const { state } = useErrorBoard();
  const errorOverviews = state.data.errorOverviews;
  const isLoading =
    errorOverviews === undefined &&
    (state.status === "loading" || state.status === "refreshing");
  const isEmpty = errorOverviews?.frequentErrors.length === 0;

  const { t } = useTranslation("errorBoard");

  return (
    <Link
      className={styles.FrequentErrorCard}
      to={getLinkToOpenErrorFrequencyModal()}
    >
      <div className={styles.FrequentErrorHeader}>
        <Typography type="body-3" color="onPrimaryHigh">
          {t("frequentErrors")}
        </Typography>
        <ChevronCircle />
      </div>
      {isEmpty || (state.status === "error" && !errorOverviews) ? (
        <div className={[styles.NoErrorWrapper, styles.FadeInMoveUp].join(" ")}>
          <div className={styles.TextWrapper}>
            <Typography type="body-3" color="onSurfaceDisabled">
              {t("noFrequentErrorsNow")}
            </Typography>
          </div>
        </div>
      ) : null}

      <div className={styles.FrequentErrorBody}>
        {isLoading
          ? new Array(3).fill(null).map((_, index) => (
              <div key={index} className={styles.FrequentRow}>
                <Typography type="body-3" color="onSurfaceHigh">
                  <Skeleton width={50} />
                </Typography>

                <Typography type="body-3" color="onSurfaceHigh">
                  <Skeleton width={20} />
                </Typography>
              </div>
            ))
          : null}

        {errorOverviews?.frequentErrors.map((frequentError, index) => (
          <div
            key={index}
            className={[
              isLoading ? "" : styles.FadeInMoveUp,
              styles.FrequentRow,
            ].join(" ")}
          >
            <div className={styles.ErrorText}>
              <Typography type="body-3" color="onSurfaceHigh">
                {`${t("error")} ${frequentError.errorDetail}`}
              </Typography>
            </div>
            <div className={styles.CountText}>
              <Typography type="body-3" color="onSurfaceHigh">
                {`(${frequentError.frequency})`}
              </Typography>
            </div>
          </div>
        ))}
      </div>
    </Link>
  );
}

function ChevronCircle() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15.3334C3.94991 15.3334 0.666664 12.0502 0.666664 8.00008C0.666664 3.94999 3.94991 0.666748 8 0.666748C12.0501 0.666748 15.3333 3.94999 15.3333 8.00008C15.3333 12.0502 12.0501 15.3334 8 15.3334ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM7.13807 4.86198L6.19526 5.80479L8.39052 8.00005L6.19526 10.1953L7.13807 11.1381L10.2761 8.00005L7.13807 4.86198Z"
        fill="white"
      />
    </svg>
  );
}

function StatusIndicator({ status }: { status: OverallScoreStatus }) {
  switch (status) {
    case "GOOD":
      return <Icon name="ReactionsSmiled" color="success" />;
    case "NEUTRAL":
      return <Icon name="ReactionsNeutral" color="warning" />;
    case "BAD":
      return <Icon name="ReactionsSad" color="error" />;
    default:
      return null;
  }
}

function Card({
  title,
  value,
  className,
  subLabel,
  isLoading,
}: {
  title: string;
  value: string | number | React.ReactNode;
  className?: string;
  subLabel?: React.ReactNode | string;
  isLoading?: boolean;
}) {
  return (
    <div className={[styles.Card, className].join(" ")}>
      <Typography
        type="body-3"
        color="onSurfaceMedium"
        className={styles.TitleText}
      >
        {title}
      </Typography>
      <div
        className={[
          styles.BottomHalfContainer,
          isLoading ? "" : styles.FadeInMoveUp,
        ].join(" ")}
      >
        {isLoading ? (
          <Typography type="headline-6" color="onSurfaceHigh">
            <Skeleton width={50} />
          </Typography>
        ) : typeof value === "string" || typeof value === "number" ? (
          <Typography type="headline-6" color="onSurfaceHigh">
            {value}
          </Typography>
        ) : (
          value
        )}

        <div className={styles.LabelContainer}>
          {isLoading ? (
            <Typography type="body-3" color="onSurfaceMedium">
              <Skeleton width={80} />
            </Typography>
          ) : typeof subLabel === "string" || typeof subLabel === "number" ? (
            <Typography type="body-3" color="onSurfaceMedium">
              {subLabel}
            </Typography>
          ) : (
            subLabel
          )}
        </div>
      </div>
    </div>
  );
}
