import * as React from "react";

import TextButton from "common/components/textbutton/TextButton";
import { Modal } from "@mui/material";
import DatePicker from "components/DatePicker";
import * as moment from "moment";
import Button from "components/Button";
import analytics from "utils/analytics";
import { ReportType, useGenerateReportMutation } from "gql/generated";
import { useTranslation } from "react-i18next";

export function DownloadTransactionReportModal() {
  const { t } = useTranslation("transaction", {
    keyPrefix: "downloadTransaction",
  });
  const { mutate, isLoading } = useGenerateReportMutation({
    onSuccess: (data) => {
      window.open(`/download-report/${data.generateReport}`);
      setDate(null);
      setOpen(false);
    },
  });
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState<Date | null>(null);
  return (
    <>
      <TextButton
        className="ml-[30px]"
        text={t("button")}
        icon="Download"
        onClick={() => {
          setOpen(true);
          analytics.track({
            name: "download transaction report button clicked",
            properties: {},
          });
        }}
      />

      <Modal
        className="flex items-center justify-center fixed !outline-none !outline-0"
        open={open}
        onClose={() => {
          setOpen(false);
          analytics.track({
            name: "modal close by backdrop clicked on transaction report modal",
            properties: {},
          });
        }}
      >
        <div className="relative w-full max-w-[420px] bg-white p-5 space-y-3 rounded-md !outline-none !outline-0">
          <h3 className="text-h6 text-on-surface-high font-kanit text-center">
            {t("modal.title")}
          </h3>

          <DatePicker
            value={date ? moment(date) : null}
            label={t("modal.date_input_placeholder")}
            maxDate={moment(Date.now())}
            onChange={(e) => {
              setDate(e?.toDate());
              analytics.track({
                name: "onChange on download transaction report modal date picker",
                properties: {
                  date: e?.toDate(),
                },
              });
            }}
          />
          <div className="flex justify-between gap-3">
            <Button
              type="secondary"
              onClick={() => setOpen(false)}
              data-tracking-event="cancel button clicked on request transaction report"
            >
              {t("modal.cancel_button")}
            </Button>
            <Button
              disabled={!date}
              type="primary"
              loading={isLoading}
              onClick={() =>
                mutate({
                  params: {
                    startDate: moment(date).format("yyyy-MM-DD"),
                    endDate: moment(date).format("yyyy-MM-DD"),
                    reportType: ReportType.SalesGroupByNone,
                  },
                })
              }
              data-tracking-event="download button clicked on request transaction report modal"
              data-tracking-property-date={date}
            >
              {t("modal.download_button")}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
