import { SortingState } from "@tanstack/react-table";
import { MachineMonitorSortField, MachineMonitorSortInput, SortingOrder } from "gql/generated";



function mapSortIdToMachineMonitorSortField (id: string): MachineMonitorSortField {
  switch (id) {
    case "todaySale":
      return MachineMonitorSortField.TodaySale;
    case "lastSold":
      return MachineMonitorSortField.LastSold;
    case "expectedSale":
      return MachineMonitorSortField.ExpectedSale;
    case "todayCupSold":
      return MachineMonitorSortField.TodaySaleCount;
    case "refillZoneName":
      return MachineMonitorSortField.RefillZone;
    default:
      return null;
  }
}

export function mapSortStateToSortFilter (sortState: SortingState): MachineMonitorSortInput {
  
  if(sortState && sortState.length > 0) {
    const sortByEnum = mapSortIdToMachineMonitorSortField(sortState[0].id);
    if(!sortByEnum) {
      return undefined;
    }
    return {
      order: sortState[0].desc ? SortingOrder.Desc : SortingOrder.Asc,
      orderBy: sortByEnum,
    };
  }
  return undefined;
}
