// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TopNav--30UeYVxPeo{display:flex;flex-direction:row;align-items:center;justify-content:flex-end;box-sizing:border-box;position:fixed;top:0;left:0;right:0;background-color:#fff;margin-left:60px;height:60px;border-bottom:1px solid rgba(0,0,0,.12);padding:10px;z-index:6}.TopNav--30UeYVxPeo>*{border-left:1px #d7d7d7 solid}@media screen and (max-width: 768px){.TopNav--30UeYVxPeo{margin-left:0}}.TopNav--30UeYVxPeo .button--3411LkoJ1D{display:flex;justify-content:center;align-items:center;width:60px;height:60px;position:relative}.TopNav--30UeYVxPeo .button--3411LkoJ1D .iconContainer--JmULIa_etB{display:flex;justify-content:center;align-items:center}.TopNav--30UeYVxPeo .avatar--1TCVkCpc2H{width:35px;height:35px;border-radius:100%;margin-left:10px;background-color:#f3f3fd}.TopNav--30UeYVxPeo .avatar--1TCVkCpc2H img{border-radius:100%;width:100%;height:100%;object-fit:cover}.TopNav--30UeYVxPeo>.organisation--1ZsS6OaDOW{display:flex;align-items:center;min-width:180px;height:60px;position:relative}.TopNav--30UeYVxPeo>.organisation--1ZsS6OaDOW .organisationPicture--10yGE8fK7q{max-width:40px;max-height:40px;border-radius:40px;background-color:#f3f3fd;margin-right:10px;margin-left:10px}.TopNav--30UeYVxPeo>.organisation--1ZsS6OaDOW .organisationPicture--10yGE8fK7q .image--2U_ryPoNHD{width:40px;height:40px;border-radius:40px;object-fit:cover}.TopNav--30UeYVxPeo .user--3TANVxr_SX{display:flex;align-items:center;height:60px}.select--NW4jlL2ha9{max-width:150px}.orgName--xEIJgnz1d8{min-width:150px}.ViewAllContainer--3PFMDQ5kBH{display:flex;justify-content:center;align-items:center;min-width:150px;height:60px;padding:0 12px;position:relative}.ViewAllContainer--3PFMDQ5kBH .toggleButtonText--22l4-dpWXI{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"TopNav": "TopNav--30UeYVxPeo",
	"button": "button--3411LkoJ1D",
	"iconContainer": "iconContainer--JmULIa_etB",
	"avatar": "avatar--1TCVkCpc2H",
	"organisation": "organisation--1ZsS6OaDOW",
	"organisationPicture": "organisationPicture--10yGE8fK7q",
	"image": "image--2U_ryPoNHD",
	"user": "user--3TANVxr_SX",
	"select": "select--NW4jlL2ha9",
	"orgName": "orgName--xEIJgnz1d8",
	"ViewAllContainer": "ViewAllContainer--3PFMDQ5kBH",
	"toggleButtonText": "toggleButtonText--22l4-dpWXI"
};
module.exports = exports;
