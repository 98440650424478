import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import config from "common/config";
import {
  logout,
  refreshToken,
} from "userandauth/utilsAuth";
import { useAuthStore } from "userandauth/useAuthStore";

import { serializeError } from "./errorService";
import { toast } from "react-toastify";
import i18n from "common/i18n";
import { toasti18n } from "utils/toast";
import analytics from "utils/analytics";

export const httpClient: AxiosInstance = axios.create({
  baseURL: config.API_URL,
  timeout: 60000,
  transitional: {
    clarifyTimeoutError: true,
    silentJSONParsing: true,
    forcedJSONParsing: true,
  },
});

httpClient.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const { accessToken } = useAuthStore.getState();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let logoutPromise = null;

let refreshTokenPromise: Promise<string | void> = null;

const getRefreshToken = () => refreshToken().then(token => token).catch((error) => {
  throw error;
});

httpClient.interceptors.response.use(
  function handleResponse(response: AxiosResponse): AxiosResponse {
    if (response.data === undefined) {
      console.warn(
        `${response.config.url} returning undefined with status ${response.status}`
      );
    }
    return response;
  },
  async function handleError(
    error: AxiosError
  ): Promise<AxiosError | undefined> {

    if (error.config.url === "/auth/refresh-token" || error.config.url === "/auth/login-password") {
      throw error;
    }

    if (error.config.url === "/auth/clean-cache") {
      return; // Clean-cache returns 400? - Need to prevent from failing
    }

    if (error.response?.status === 401) {
      if(!refreshTokenPromise) {
        refreshTokenPromise = getRefreshToken().then((token) => {
          refreshTokenPromise = null;
          return token;
        }).catch(async () => {
          if (logoutPromise === null && useAuthStore.getState().isLoggedIn) {
            analytics.track({
              name: "force logout",
              properties: {
                status: error.response?.status,
                code: error?.code,
                message: error.response?.statusText
              }
            });
            logoutPromise = logout();
            await logoutPromise;
            logoutPromise = null;
            
            throw error;
          }
        });
      }
      return refreshTokenPromise.then(token => {
        error.config.headers['Authorization'] = token;
        return httpClient.request(error.config);
      });
    }


    if (error.response?.status === 500) {
      toast.error(i18n.t("toast_500_error"));
    }
    if (error.response?.status === 403 && error.config.url !== '/auth/query-permissions') {
      toast.error(i18n.t("toast_unauthorized"));
    }
    if (error.response?.status === 404) {
      toast.error(i18n.t("toast_404_error"));
    }

    if (error.response?.status === 409) {
      toast.error([
        i18n.t("toast_409_error"),
        serializeError(error) ?? ""
      ].join(" "));
    }

    if (error.response?.status === 429) {
      toast.error(i18n.t("toast_too_many_requests"));
    }

    if (
      error.response?.status === 400 &&
      error.config.url !== "/auth/refresh-token"
    ) {
      toast.error(
        [i18n.t("toast_400_error"), serializeError(error) ?? ""].join(" ")
      );
    }

    if (error.code === "ETIMEDOUT") {
      toasti18n.error({ message: `${error.config.url} Timeout` });
    }

    if (error.code === "ECONNABORTED") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    error.message = `URL: ${error.config.url} STATUS: ${
      error.response?.status
    } MESSAGE: ${serializeError(error)}`;

    throw error;
  }
);
