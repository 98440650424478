import analytics from "utils/analytics";
import * as moment from "moment";
import { RefillInventoryInput } from "gql/generated";
import { KittingModalState } from "./useKittingModalState";

type TrackMachineKittingPlanGeneratedArgs = {
  planId: string;
  variables: {
    planId: string;
    refillInventories: RefillInventoryInput | RefillInventoryInput[];
  };
  data: KittingModalState;
};

export function trackMachineKittingPlanGenerated({
  planId,
  data,
  variables,
}: TrackMachineKittingPlanGeneratedArgs) {
  for (const [, machine] of Object.entries(data.machines)) {
    
    const refillInventories = Array.isArray(variables.refillInventories)
      ? variables.refillInventories
      : [variables.refillInventories];
    const inventoryArray = Object.values(machine.inventory);

    // initial recommend 
    const initializeSelectionState = Object.values(data.initializeSelectionState[machine.machineId]);
    const recommendedSlotIds = initializeSelectionState.filter(slot => slot.selected).map(ri => ri.slotName);
    const notRecommendedSlotIds = initializeSelectionState.filter(slot => !slot.selected).map(ri => ri.slotName);
    const userSelectedIds = inventoryArray.filter(slot => slot.selected).map(ri => ri.slot);
    const listOfSlotRecommendSelected = userSelectedIds.filter(ri => recommendedSlotIds.includes(ri));
    const listOfSlotNotRecommendSelected = userSelectedIds.filter(ri => !recommendedSlotIds.includes(ri));
    const listOfSlotRecommendNotSelected = recommendedSlotIds.filter(ri => !userSelectedIds.includes(ri));
    const listOfSlotNotRecommendNotSelected = notRecommendedSlotIds.filter(ri => !userSelectedIds.includes(ri));

    // calculate total ingredient increase/decrease base on recommended amount
    const totalIngredientChanges = inventoryArray.filter(inventory => inventory.selected).reduce((acc, current) => {
      const change = current.productToUse.amountUsedInPackages - (current.productToUse.amountRequired.roundedToPackageSize/current.productToUse.packageSize) ;
      if( change > 0) {
        return { ...acc, ingredientIncrease: acc.ingredientIncrease + change };
      } else {
        return { ...acc, ingredientDecrease: acc.ingredientDecrease + Math.abs(change)};
      }
    
    }, { ingredientIncrease: 0, ingredientDecrease: 0 });
  

    const recommendedInventories = inventoryArray.filter(
      (inventory) => inventory.recommendedAction != null
    );

    const recommendedInventoriesIds = recommendedInventories.map(
      (ri) => ri.inventoryId
    );

    const inventorySelectedIds = refillInventories
      .find((ri) => ri.machineId === machine.machineId)
      .kittedItems.map((ki) => ki.machineInventoryId);

    const locationData = {
      no_of_cupC1_since_last_refill:
        inventoryArray.find((i) => i.slot === "C1")?.inventoryLevel?.current ||
        null,
      no_of_cupC2_since_last_refill:
        inventoryArray.find((i) => i.slot === "C2")?.inventoryLevel?.current ||
        null,
      no_of_cupC3_since_last_refill:
        inventoryArray.find((i) => i.slot === "C3")?.inventoryLevel?.current ||
        null,
      no_of_not_recommended_slot_selected: inventorySelectedIds.filter(
        (is) => !recommendedInventoriesIds.includes(is)
      ).length,
      no_of_recommended_slot: recommendedInventories.length,
      no_of_recommended_slot_selected: recommendedInventories.filter((ri) =>
        inventorySelectedIds.includes(ri.inventoryId)
      ).length,
      day_since_last_refill: Number(machine.lastRefilled)
        ? moment().diff(moment(Number(machine.lastRefilled)), "days")
        : "-",
      location_grade: machine.location.grade,
      location_id: machine.location.id,
      location_name: machine.location.name,
      location_type: machine.location.type,
      machine_id: machine.machineId,
      plan_id: planId,
      warehouseId: data.plan.warehouseId,
      revenue_since_last_refill: machine.location.rawRevenue,
      total_cash: machine.cashValue,
      total_slot: initializeSelectionState.length,
      total_slot_recommended_selected: listOfSlotRecommendSelected.length,
      total_slot_not_recommended_selected: listOfSlotNotRecommendSelected.length,
      total_slot_recommended_not_selected: listOfSlotRecommendNotSelected.length,
      total_slot_not_recommended_not_selected: listOfSlotNotRecommendNotSelected.length,
      slot_recommended_selected: listOfSlotRecommendSelected.join(","),
      slot_not_recommended_selected: listOfSlotNotRecommendSelected.join(","),
      slot_recommended_not_selected: listOfSlotRecommendNotSelected.join(","),
      slot_not_recommended_not_selected: listOfSlotNotRecommendNotSelected.join(","),
      total_ingredient_increase: totalIngredientChanges.ingredientIncrease,
      total_ingredient_decrease: totalIngredientChanges.ingredientDecrease,
    };

    analytics.track({
      name: "machine kitting plan generated",
      properties: locationData,
    });
  }
}

export function trackPlanMoveToPrekits(data: KittingModalState) {
  analytics.track({
    name: "plan moved to prekits",
    properties: {
      plan_id: data?.plan?.planId,
      plan_name: data?.plan?.planName,
      warehouse_id: data?.plan?.warehouseId,
      no_of_machine_in_plan: Object.keys(data?.machines)?.length,
    },
  });
}

type trackKittingAmountAdjustedProps = {
  adjustment: "increase" | "decrease";
  inventory: KittingModalState["machines"][string]["inventory"][string];
  state: KittingModalState;
}

export function trackKittingAmountAdjusted({ inventory, adjustment, state }: trackKittingAmountAdjustedProps) {
  analytics.track({
    name: "kitting amount adjusted",
    properties: {
      plan_name: state.plan.planName,
      machine_id: inventory.machineId,
      checked: inventory.selected,
      adjustment: adjustment === "increase" ? "+1": "-1",
      slot_name: inventory.slot,
      current_level: inventory.inventoryLevel.current,
      ingredient_code: inventory.productToUse.sku,
      ingredient_name: inventory.productToUse.name,
      slot_status: inventory.inventoryStatus,
      uom: inventory.productToUse.uom,
      package_size: inventory.productToUse.packageSize,
      recommended_refill_amount: inventory.productToUse.amountRequired.roundedToPackageSize/inventory.productToUse.packageSize,
      amount: adjustment === "increase" ? inventory.productToUse.amountUsedInPackages + 1: inventory.productToUse.amountUsedInPackages - 1,
    }
  });
}