import * as React from "react";
import { Table } from "@tanstack/table-core/build/lib/types";
import { MenuItem } from "@mui/material";
import { CustomSelect } from "../inputs/customSelect";
import { CustomDebouncedInput } from "./input";
import { tableV2Tracking } from "components/tableV2/tracking";

type FilterProps<T> = {
  table: Table<T>;
};

export function PrimaryFilter<T>({ table }: FilterProps<T>) {
  const tableName = table.getState().tableName;
  const [activeFilter, setActiveFilter] = React.useState("");
  const allLeafColumns = React.useMemo(() => {
    return table
      .getAllLeafColumns()
      .filter(
        (column) =>
          column.columnDef.meta?.columnFilter?.location === "primary" &&
          !column.columnDef.meta?.hide
      );
  }, [table]);

  React.useEffect(() => {
    if (allLeafColumns && allLeafColumns.length > 0) {
      setActiveFilter(allLeafColumns[0].id);
    }
  }, [allLeafColumns]);

  if (allLeafColumns.length === 0) return null;

  return (
    <div className="flex items-start flex-1">
      <div>
        <CustomSelect
          key={activeFilter}
          className="sm:!w-[140px] !font-kanit !font-light !text-on-surface-high !rounded-r-none !border-r-0"
          value={activeFilter}
          label="filter"
          onChange={(e) => {
            setActiveFilter(e.target.value as string);
            tableV2Tracking({
              name: "primary filter dropdown on change",
              properties: {
                column_id: e.target.value,
              },
              tableName,
            });
          }}
          size="small"
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRight: "none",
              },
            },
          }}
        >
          {allLeafColumns
            ?.sort(
              (a, b) =>
                a?.columnDef?.meta?.columnFilter?.order -
                b?.columnDef?.meta?.columnFilter?.order
            )
            .map((column) => (
              <MenuItem
                key={column.id}
                className="!font-kanit"
                value={column.id}
              >
                {column.columnDef.meta?.columnFilter?.label || column.id}
              </MenuItem>
            ))}
        </CustomSelect>
      </div>
      {allLeafColumns
        ?.filter((column) => column.id === activeFilter)
        ?.map((column) => (
          <CustomDebouncedInput
            key={column.id}
            column={column}
            onChange={(e) =>
              tableV2Tracking({
                name: "primary filter text on change",
                properties: {
                  column_id: column.id,
                  value: e,
                  location: "primary",
                },
                tableName,
              })
            }
          />
        ))}
    </div>
  );
}
