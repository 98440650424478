// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Toast--3lTFWr648I{position:fixed;top:-50px;left:50%;padding:10px 15px;border-radius:4px;box-shadow:0px 2px 5px 1px rgba(0,0,0,.16);min-width:120px;display:flex;flex-direction:row;align-items:center;justify-content:space-between;transition:top 100ms ease-in;z-index:999}@media screen and (max-width: 768px){.Toast--3lTFWr648I{width:80%}}.Toast--3lTFWr648I.open--3Azhiny2j_{top:20px}.Toast--3lTFWr648I.success--2UFr9KCFR9{background-color:#27b6ba}.Toast--3lTFWr648I.warning--1ZH-6oxVU6{background-color:#ffb025}.Toast--3lTFWr648I.error--2UC0403bCW{background-color:#e95d50}.Toast--3lTFWr648I .left--slCbRWcs9C{display:flex;flex-direction:column;align-items:flex-start;margin-right:10px}.Toast--3lTFWr648I .left--slCbRWcs9C .text--3gmmo-LB6q{margin:0;color:#fff}.Toast--3lTFWr648I .close--3XxS5Ej3lI{display:flex;justify-content:center;align-items:center;cursor:pointer}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "error--2UC0403bCW",
	"critical": "#e95d50",
	"success": "success--2UFr9KCFR9",
	"successOverlay": "#b2d3d3",
	"warning": "warning--1ZH-6oxVU6",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Toast": "Toast--3lTFWr648I",
	"open": "open--3Azhiny2j_",
	"left": "left--slCbRWcs9C",
	"text": "text--3gmmo-LB6q",
	"close": "close--3XxS5Ej3lI"
};
module.exports = exports;
