import * as React from "react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import * as moment from "moment";
import {
  FocusedInputShape,
  DateRangePicker as RDDateRangePicker,
} from "react-dates";
import {
  START_DATE,
  END_DATE,
  VERTICAL_ORIENTATION,
  HORIZONTAL_ORIENTATION,
} from "react-dates/constants";

import * as styles from "./DateRangePicker.module.scss";

interface IDates {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface IOnPureDateRangePickerBlur {
  startDate: number;
  endDate: number;
}

interface IPureDateRangePickerProps {
  defaultStartDate?: number;
  defaultEndDate?: number;
  maxPastDays?: number;
  maxFutureDays?: number;
  className?: string;
  onBlur: ({ startDate, endDate }: IOnPureDateRangePickerBlur) => void;
  startDatePlaceholderText?: string;
  endDatePlaceholderText?: string;
  selectRange?: number;
}

function PureDateRangePicker({
  defaultStartDate,
  defaultEndDate,
  maxPastDays = 365,
  maxFutureDays = 0,
  className,
  onBlur,
  endDatePlaceholderText,
  startDatePlaceholderText,
  selectRange,
}: IPureDateRangePickerProps): JSX.Element {
  const [startDate, setStartDate] = useState(() =>
    defaultStartDate ? moment(defaultStartDate) : undefined
  );
  const [endDate, setEndDate] = useState(() =>
    defaultEndDate ? moment(defaultEndDate) : undefined
  );
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );

  function onFocusChange(e: FocusedInputShape) {
    setFocusedInput(e);
    if (e === null) {
      startDate &&
        endDate &&
        onBlur({
          startDate: startDate.set({ hour: 0, minute: 0, second: 0 }).valueOf(),
          endDate: endDate.set({ hour: 23, minute: 59, second: 59 }).valueOf(),
        });
    }
  }

  function handleDateChange(day: IDates): void {
    setStartDate(day.startDate);
    if (day.endDate !== null) {
      setEndDate(day.endDate);
    }
    if (!selectRange) return;
    if (
      day.endDate &&
      moment(day.endDate).diff(moment(day.startDate), "days") >= selectRange
    ) {
      setStartDate(moment(day.endDate).subtract(selectRange, "days"));
    } else if (
      !day.endDate &&
      moment(endDate).diff(moment(day.startDate), "days") >= selectRange
    ) {
      setEndDate(moment(day.startDate).add(selectRange, "days"));
    }
  }

  return (
    <div className={[styles.DateRangePickerWrapper, className].join(" ")}>
      <RDDateRangePicker
        startDate={startDate}
        startDateId={START_DATE}
        endDate={endDate}
        endDateId={END_DATE}
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        onFocusChange={onFocusChange}
        hideKeyboardShortcutsPanel
        initialVisibleMonth={() => moment().subtract(1, "month")}
        isOutsideRange={(day) =>
          day.isBefore(
            moment()
              .set({ hour: 0, minute: 0, second: 0 })
              .subtract(maxPastDays, "days")
          ) ||
          day.isAfter(
            moment()
              .set({ hour: 23, minute: 59, second: 59 })
              .add(maxFutureDays, "days")
          )
        }
        customArrowIcon={<span>-</span>}
        horizontalMargin={20}
        orientation={isMobile ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION}
        minimumNights={0}
        noBorder
        readOnly
        keepOpenOnDateSelect
      />
    </div>
  );
}

export default PureDateRangePicker;
