// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BinaryStatus--1Sq0LcQ27r{padding:20px;border-radius:4px;display:flex;flex-direction:column;flex:1;background-color:#fff;margin-bottom:20px}.BinaryStatus--1Sq0LcQ27r .header--2D5yr7lqsS{display:flex;flex-direction:row;justify-content:space-between;align-items:center;margin-bottom:20px}.BinaryStatus--1Sq0LcQ27r .header--2D5yr7lqsS .legend--1qRU6FVWUx{display:flex;flex-direction:row;align-items:center}.BinaryStatus--1Sq0LcQ27r .header--2D5yr7lqsS .legend--1qRU6FVWUx .indicator--2pRUztk2oc{margin-left:20px}@media screen and (max-width: 768px){.BinaryStatus--1Sq0LcQ27r .header--2D5yr7lqsS .legend--1qRU6FVWUx{flex-direction:column;align-items:flex-end}}.BinaryStatus--1Sq0LcQ27r .bar--34g3wp3DoG{border-radius:4px;height:24px;background-color:#ebebeb;display:flex;flex-direction:row;position:relative}.BinaryStatus--1Sq0LcQ27r .bar--34g3wp3DoG .tooltipContent--3MP57oMQX4{min-width:230px}.BinaryStatus--1Sq0LcQ27r .bar--34g3wp3DoG .positionWrapper--14VitjbP4F:first-child{border-top-left-radius:4px;border-bottom-left-radius:4px}.BinaryStatus--1Sq0LcQ27r .bar--34g3wp3DoG .positionWrapper--14VitjbP4F:last-child{border-top-right-radius:4px;border-bottom-right-radius:4px}.BinaryStatus--1Sq0LcQ27r .bar--34g3wp3DoG .positionWrapper--14VitjbP4F .position--2YqFTV_xRK{height:24px;border-radius:inherit}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"BinaryStatus": "BinaryStatus--1Sq0LcQ27r",
	"header": "header--2D5yr7lqsS",
	"legend": "legend--1qRU6FVWUx",
	"indicator": "indicator--2pRUztk2oc",
	"bar": "bar--34g3wp3DoG",
	"tooltipContent": "tooltipContent--3MP57oMQX4",
	"positionWrapper": "positionWrapper--14VitjbP4F",
	"position": "position--2YqFTV_xRK"
};
module.exports = exports;
