import * as React from "react";
import * as z from "zod";
import { Redirect } from "react-router";
import { useAuthStore } from "userandauth/useAuthStore";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";

import Icon from "common/components/icon/Icon";
import TextInput from "components/TextField";
import Button from "components/Button/Button";
import { httpClient } from "common/services/transportService";


export function ForgotPassword(): JSX.Element {
  const { t } = useTranslation("authentication", {
    keyPrefix: "forgot_password",
  });
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState<{ email?: string }>({});

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  // Define the Zod schema
  const forgotPasswordSchema = z.object({
    email: z
      .string({
        message: t("label_email_required"),
      })
      .email(t("label_invalid_email")),
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validate using Zod
    const validationResult = forgotPasswordSchema.safeParse({ email });

    if (!validationResult.success) {
      const validationErrors = validationResult.error.format();
      setErrors({
        email: validationErrors.email?._errors[0],
      });
      return;
    }

    try {
      const response = await httpClient.post<string>(
        "/auth/request-password-reset",
        {
          email: email,
        }
      );

      if (response.status === 200) {
        toast.success(t("reset_password_email_sent"));
        setEmail(""); // Reset form
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div className="flex flex-col items-center h-screen bg-navigation">
      <div className="z-10 mt-16 mx-5 flex flex-col items-center w-full box-border sm:mt-5">
        <div className="w-36 mb-8 sm:80 sm:mb-5">
          <Icon name="Vendii" className="w-full h-auto block" />
        </div>
        <div className="flex flex-col max-w-md w-full bg-surface rounded-lg p-10 mx-5 box-border shadow-lg sm:p-5">
          <h3 className="text-h6 mb-5 text-center">
            {t("forgot_password_title")}
          </h3>
          <form className="space-y-5">
            <div>
              <TextInput
                type="text"
                label={t("label_email")}
                value={email}
                onChange={(value) => setEmail(value.target.value)}
              />
              {errors.email && (
                <span className="text-error text-caption">{errors.email}</span>
              )}
            </div>
            <div className="flex justify-center">
              <Button type="primary" size="medium" onClick={handleSubmit}>
                {t("label_reset_password")}
              </Button>
            </div>
          </form>
          <hr className="h-px my-3 bg-outline border-0 w-full" />
          <div className="flex items-center gap-1 justify-center">
            <Icon className="-rotate-90" name="ArrowTop" color="primary" />
            <Link
              to="/login"
              className="text-primary font-main font-medium hover:underline"
            >
              {t("label_back_to_login")}
            </Link>
          </div>
        </div>
      </div>
      <img
        alt="vending city splash screen"
        className="fixed object-contain bottom-0 w-full tablet:w-auto tablet:left-0 tablet:h-[90%] mobile:h-[50%]"
        src="/images/vendingcity.png"
      />
    </div>
  );
}
